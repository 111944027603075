<style scoped>
</style>
<template>
  <div class="vue-box">
    <div class="c-panel">
      <div class="c-title">支付网关入口配置</div>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新
          </el-button>
          <el-button type="primary" icon="el-icon-plus" @click="add()">添加网关支付入口</el-button>
          <el-button type="primary" icon="el-icon-sort" @click="showUserInfoPage()">切换码队</el-button>
        </el-form-item>
        <br>
        <el-form-item>
          <b>网关当前已分配码队:</b>
          <el-tag v-for="tag in gatewayTags" :key="tag.codeMchId" size="small" closable style="margin: 2px;"
                  @close="handleClose(tag)">
            {{ tag.codeMchName }}
          </el-tag>
        </el-form-item>
      </el-form>
      <el-table :row-class-name="tableRowClassName" :data="dataList" :key="conditionsKey"
                @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }"
                :cell-style="{ 'text-align': 'center' }" size="mini">
        <el-table-column label="通道类型" prop="payType" width="150px"></el-table-column>
        <el-table-column label="系统内部通道类型" prop="sysType" width="150px"></el-table-column>
        <el-table-column label="状态" width="120px">
          <template slot-scope="s">
            <el-switch :value="s.row.status" :active-value="1" :inactive-value="0" inactive-color="#ff4949"
                       @change="setStatus(s.row)"></el-switch>
            <b style="color: green; margin-left: 10px" v-if="s.row.status == 1">启用</b>
            <b style="color: red; margin-left: 10px" v-if="s.row.status == 0">禁用</b>
          </template>
        </el-table-column>
        <el-table-column label="使用优惠券" width="120px">
          <template slot-scope="s">
            <i :style="s.row.couponSwitch===1 ? 'color: red;' : 'color: #a19f9d;'" class="el-icon-s-ticket"></i>
            <b style="color: red; " v-if="s.row.couponSwitch===1 ">是</b>
            <b style="color: #a19f9d;" v-if="!s.row.couponSwitch===0">否</b>
          </template>
        </el-table-column>
        <el-table-column label="接口限额" min-width="70px">
          <template slot-scope="s">
            <b>￥{{ s.row.limits }}</b>
          </template>
        </el-table-column>
        <el-table-column label="下单限额" min-width="70px">
          <template slot-scope="s">
            <b>￥{{ s.row.min }} ~ ￥{{ s.row.max }}</b>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remarks" :formatter="remarksFormat">
        </el-table-column>
        <el-table-column prop="address" label="操作" width="200px" fixed="right">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" icon="el-icon-edit" @click="update(s.row)">编辑</el-button>
            <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page"
                       :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]"
                       @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
    <AddSifangPayment ref="add-sifang-payment"></AddSifangPayment>
    <el-dialog :title="title" :visible.sync="userDialogVisible" v-if="userDialogVisible" width="550px" append-to-body>
      <SwitchGatewayCodeMch @mchId="getSwitchMchId" :select="switchMchId"></SwitchGatewayCodeMch>
      <div slot="footer" class="dialog-footer">
        <el-button @click="switchMch()">切 换</el-button>
        <el-button @click="cancel()">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AddSifangPayment from './add-sifang-payment.vue'
import SwitchGatewayCodeMch from './switch-gateway-code-mch.vue'

export default {
  components: { AddSifangPayment, SwitchGatewayCodeMch },
  data () {
    return {
      p: {
        // 查询参数
        channelId: null,
        page: 1,
        type: 1,
        limit: 10,
      },
      title: '切换当前网关码队',
      userDialogVisible: false,
      dataCount: 0,
      dataList: [],
      selectList: [],
      conditionsKey: 0,
      drawer: false,
      direction: 'rtl',
      gatewayTags: [],
      multipleSelection: [],
      switchMchId: [],
    }
  },
  methods: {
    // 数据刷新
    f5: function () {
      if (this.sa_admin.role == 2) {
        var defaultCfg = {
          isBody: true, //是否是请求体请求
        }
        this.sa.ajax(
          '/v1/sifang/list',
          this.p,
          function (res) {
            this.dataList = res.data.records // 数据
            this.dataCount = res.data.total // 分页
          }.bind(this),
          defaultCfg,
        )
        defaultCfg = {
          isBody: false, //是否是请求体请求
          type: 'get',
        }
        this.sa.ajax(
          '/v1/sifang/getGateWayCodeMch?id=2',
          function (res) {
            this.gatewayTags = res.data
            // 使用循环来填充数组
            this.switchMchId = []
            for (let i = 0; i < this.gatewayTags.length; i++) {
              this.switchMchId.push(this.gatewayTags[i].codeMchId)
            }
          }.bind(this),
          defaultCfg,
        )
      }
    },
    handleClose (tag) {
      var defaultCfg = {
        isBody: true,
      }
      this.sa.ajax(
        '/v1/mch_relation/delGatewayCodeMch', tag,
        function () {
          this.f5()
        }.bind(this), defaultCfg,
      )
    },
    showUserInfoPage () {
      this.userDialogVisible = true
    },
    getSwitchMchId (val) {
      console.log(val)
      this.switchMchId = val
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(this.multipleSelection)
    },
    setStatus: function (data) {
      this.sa.confirm(
        '是否要修改收款商户状态',
        function () {
          var defaultCfg = {
            type: 'get',
          }
          var params = data
          this.sa.ajax(
            '/v1/sifang/setStatus/' + data.id,
            function () {
              this.sa.ok('修改成功')
              params.status = params.status == 1 ? 0 : 1
            }.bind(this), defaultCfg,
          )
        }.bind(this),
      )
    },
    add: function () {
      this.$refs['add-sifang-payment'].open(0, this.p.type)
    },
    update: function (data) {
      this.$refs['add-sifang-payment'].open(data)
    },
    remarksFormat (row) {
      if (row.remarks == '' || row.remarks == null) {
        return '无备注'
      } else {
        return row.remarks
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex
    },
    del: function (data) {
      var defaultCfg = {
        type: 'delete',
      }
      this.sa.ajax(
        '/v1/sifang/delete/' + data.id,
        function () {
          this.f5()
        }.bind(this), defaultCfg,
      )
    },
    cancel () {
      this.userDialogVisible = false
      this.switchMchId == null
      this.multipleSelection == null
    },
    switchMch () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      var params = {
        gateway_id: 2,
        codeMch: this.switchMchId,
      }
      this.sa.ajax(
        '/v1/mch_relation/switchGateway',
        params,
        function () {
          this.sa.ok('切换成功')
          this.cancel()
          this.f5()
          //刷新页面
        }.bind(this),
        defaultCfg,
      )
    },
    loadmore () {
      // this.p.page++;
      // this.getMchCode();
    },
    getCustomType () {
      var defaultCfg = {
        type: 'get', //是否是请求体请求
        sleep: 0,
        msg: null,
      }
      this.sa.ajax(
        '/v1/sifang/listByType',
        function (res) {
          this.selectList = res.data // 数据
        }.bind(this),
        defaultCfg,
      )
    },
  },
  created: function () {
    this.f5()
  },

  directives: {
    'el-select-loadmore': {
      bind (el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap',
        )
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      },
    },
  },
}
</script>

<style>
</style>
